
import { Component, Vue, Watch } from 'vue-property-decorator';
import { Getter } from 'vuex-class';
import Kniveset from '@/interfaces/Kniveset';
import KnivesetConfigurationForm from '@/components/forms/KnivesetConfigurationForm.vue';
import KnivesetStatusTransitionForm from '@/components/forms/KnivesetStatusTransitionForm.vue';
import KnivesetsTable from '@/components/KnivesetsTable.vue';
import KnivesetsSelectStatusTransition from '@/components/KnivesetsSelectStatusTransition.vue';
import KnivesetsFilters from '@/components/KnivesetsFilters.vue';
import KnivesetStatusType, { getKnivesetStatusTypeLabel } from '@/enums/KnivesetStatusType';
import KnivesetsFiltersData from '@/interfaces/KnivesetsFiltersData';
import KnivesetStatusTransitionsExportForm
    from '@/components/forms/KnivesetStatusTransitionsExportForm.vue';

@Component({
    components: {
        KnivesetStatusTransitionsExportForm,
        KnivesetsFilters,
        KnivesetsSelectStatusTransition,
        KnivesetsTable,
        KnivesetStatusTransitionForm,
        KnivesetConfigurationForm,
    },
})
export default class KnivesetsView extends Vue {
    @Getter('knivesets')
    knivesets!: Kniveset[];

    @Getter('filterKnivesets')
    filterKnivesets!: (filters: KnivesetsFiltersData) => Kniveset[];

    selectedKnivesetIds: number[] = [];

    editKniveset: Kniveset | null = null;

    statusTransitionType: KnivesetStatusType | null = null;

    statusTransitionKnivesets: Kniveset[] = [];

    filters: KnivesetsFiltersData = {
        farmId: null,
        knivesetStatusType: null,
    };

    get selectedKnivesets(): Kniveset[] {
        return this.selectedKnivesetIds
            .map(id => this.knivesets.find(kniveset => kniveset.id === id) as Kniveset)
            .sort((a, b) => a.number - b.number);
    }

    get filteredKnivesets(): Kniveset[] {
        return this.filterKnivesets(this.filters);
    }

    get knivesetsSharpeningRequired(): Kniveset[] {
        return this.filterKnivesets({
            knivesetStatusType: KnivesetStatusType.AT_FARM_SHARPENING_REQUIRED,
        });
    }

    get knivesetsSharpeningButtonDisabled(): boolean {
        return this.filters.farmId === null
            && this.filters.knivesetStatusType === KnivesetStatusType.AT_FARM_SHARPENING_REQUIRED
            && !this.filters.knivesetNumber;
    }

    get hasFilter(): boolean {
        return !!this.filters.farmId || !!this.filters.knivesetStatusType || !!this.filters.knivesetNumber;
    }

    get statusTransitionModalTitle(): string {
        if (!this.statusTransitionType) {
            return '';
        }

        return `${getKnivesetStatusTypeLabel(this.statusTransitionType)}
        | ${this.knivesets.length} messenset${this.knivesets.length > 1 ? 's' : ''}`;
    }

    @Watch('knivesets')
    @Watch('filters')
    resetSelected(): void {
        this.selectedKnivesetIds = [];
    }

    resetFilters(): void {
        this.filters = {
            farmId: null,
            knivesetStatusType: null,
        };
    }

    showKnivesetsSharpeningRequired(): void {
        this.filters = {
            farmId: null,
            knivesetStatusType: KnivesetStatusType.AT_FARM_SHARPENING_REQUIRED,
        };
    }

    showKnivesetSelectStatusTransitionModal(knivesets: Kniveset[], status: KnivesetStatusType): void {
        this.statusTransitionKnivesets = knivesets;
        this.statusTransitionType = status;

        this.$bvModal.show('kniveset-status-transition');
    }
}

