
import { Component, Prop } from 'vue-property-decorator';
import { Getter } from 'vuex-class';
import Kniveset from '@/interfaces/Kniveset';
import {
    LelyTableActionsConfig,
    LelyTableColumnsConfig,
    LelyTableRowStatus,
} from '@tec/frontend-vue-shared';
import Farm from '@/interfaces/Farm';
import { format } from 'date-fns';
import KnivesetStatusType, {
    getKnivesetStatusTypeLabel,
    knivesetStatusTypeLabels,
} from '@/enums/KnivesetStatusType';
import KnivesetConfigurationForm from '@/components/forms/KnivesetConfigurationForm.vue';
import KnivesetStatusTransitionForm from '@/components/forms/KnivesetStatusTransitionForm.vue';
import SharpeningRequiredReason, {
    getSharpeningRequiredReasonLabel,
} from '@/enums/SharpeningRequiredReason';
import Vue from 'vue';
import { getLastTransition } from '@/helpers';
import { LelyTableActionItem } from '@tec/frontend-vue-shared/src/core/LelyTableTypes';

@Component({
    components: {
        KnivesetStatusTransitionForm,
        KnivesetConfigurationForm,
    },
})
export default class KnivesetsTable extends Vue {
    @Prop()
    knivesets!: Kniveset[];

    @Prop({ default: () => [] })
    selectedKnivesetIds!: number[];

    @Getter('farmById')
    farmById!: (id: number) => Farm;

    actions: LelyTableActionsConfig<Kniveset> = [
        {
            name: 'action',
            icon: 'lely-icon-tasks',
            dropdown: kniveset => this.createStatusDropdown(kniveset),
        },
        {
            name: 'config',
            icon: 'lely-icon-settigns',
        },
    ];

    get selectedKnivesetIdsForTable() {
        return this.selectedKnivesetIds;
    }

    set selectedKnivesetIdsForTable(ids: number[]) {
        this.$emit('selectKnivesetIds', ids);
    }

    get columns(): LelyTableColumnsConfig<Kniveset> {
        return [
            {
                header: '#',
                value: 'number',
                sub: 'description',
                shrink: true,
                bold: true,
            },
            {
                header: 'Boerderij',
                bold: true,
                value: kniveset => (kniveset.farm_id ? this.farmById(kniveset.farm_id)?.name || '-' : '-'),
            },
            {
                header: 'Status',
                value: kniveset => this.getStatusLabel(kniveset),
                sub: kniveset => this.getStatusSince(kniveset),
            },
        ];
    }

    getStatusLabel(kniveset: Kniveset): string {
        const lastTransition = getLastTransition(kniveset);

        if (!lastTransition) {
            return '-';
        }

        const statusType = lastTransition.status_type;
        const label = getKnivesetStatusTypeLabel(statusType);

        if (statusType === KnivesetStatusType.AT_FARM_SHARPENING_REQUIRED) {
            return `${label} - ${getSharpeningRequiredReasonLabel(lastTransition.sharpening_required_reason as SharpeningRequiredReason)}`;
        }

        return label;
    }

    getStatusSince(kniveset: Kniveset): string {
        const lastTransition = getLastTransition(kniveset);

        if (!lastTransition) {
            return '';
        }

        return `Sinds ${format(new Date(lastTransition.created_at), 'dd-MM-yyyy')}`;
    }

    getTableRowStatus(kniveset: Kniveset): LelyTableRowStatus {
        const lastTransition = getLastTransition(kniveset);

        if (!lastTransition) {
            return undefined;
        }

        return lastTransition.status_type === KnivesetStatusType.AT_FARM_SHARPENING_REQUIRED
            ? 'critical' : undefined;
    }

    createStatusDropdown(kniveset: Kniveset): LelyTableActionItem<Kniveset>[] {
        const lastTransition = getLastTransition(kniveset);

        return Object.entries(knivesetStatusTypeLabels)
            .map(([key, value]) => ({
                name: key,
                label: value,
                disabled: () => lastTransition?.status_type as string === key,
            }));
    }
}

