import Kniveset from '@/interfaces/Kniveset';
import KnivesetStatusTransition from '@/interfaces/KnivesetStatusTransition';

// eslint-disable-next-line import/prefer-default-export
export const getLastTransition = (kniveset: Kniveset): KnivesetStatusTransition | null => {
    if (!kniveset.transitions?.length) {
        return null;
    }

    return kniveset.transitions[0];
};
