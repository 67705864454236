
import {
    Component, Prop, Vue, Watch,
} from 'vue-property-decorator';
import { Getter } from 'vuex-class';
import Farm from '@/interfaces/Farm';
import KnivesetStatusType, { knivesetStatusTypeLabels } from '@/enums/KnivesetStatusType';
import KnivesetsFiltersData from '@/interfaces/KnivesetsFiltersData';

@Component({
    components: {},
})
export default class KnivesetsFilters extends Vue {
    @Getter('farms')
    farms!: Farm[];

    @Prop()
    value!: KnivesetsFiltersData;

    farmId: number | null = null;

    knivesetStatusType: KnivesetStatusType | null = null;

    knivesetNumber = '';

    get statusses(): { text: string, value: KnivesetStatusType }[] {
        return Object.entries(knivesetStatusTypeLabels)
            .map(label => ({
                text: label[1],
                value: label[0] as KnivesetStatusType,
            }));
    }

    @Watch('value', { immediate: true })
    setFromValue(filters: KnivesetsFiltersData): void {
        this.farmId = filters.farmId || null;
        this.knivesetStatusType = filters.knivesetStatusType || null;
        this.knivesetNumber = filters.knivesetNumber || '';
    }

    @Watch('farmId')
    @Watch('knivesetStatusType')
    @Watch('knivesetNumber')
    updateFilters(): void {
        this.$emit('input', {
            farmId: this.farmId,
            knivesetStatusType: this.knivesetStatusType,
            knivesetNumber: this.knivesetNumber,
        } as KnivesetsFiltersData);
    }

    get hasFilters(): boolean {
        return !!this.farmId || !!this.knivesetStatusType;
    }
}

