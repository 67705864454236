import RootState from '@/store/rootState';
import Farm from '@/interfaces/Farm';
import Kniveset from '@/interfaces/Kniveset';
import { getLastTransition } from '@/helpers';
import KnivesetsFiltersData from '@/interfaces/KnivesetsFiltersData';

const sortAZ = <T>(values: T[], key: keyof T): T[] => values
    .sort((a, b) => (String(a[key])).localeCompare(String(b[key])));

export const farms = (state: RootState): Farm[] => sortAZ(state.farms, 'name');

export const knivesets = (state: RootState): Kniveset[] => state.knivesets
    .sort((a, b) => a.number - b.number);

export const farmById = (state: RootState) => (farmId: number): Farm | null => state
    .farms
    .find(farm => farm.id === farmId) || null;

export const filterKnivesets = (state: RootState) => (filters: KnivesetsFiltersData): Kniveset[] => state.knivesets
    .filter(kniveset => {
        if (filters.farmId && filters.farmId !== kniveset.farm_id) {
            return false;
        }

        if (filters.knivesetNumber && !String(kniveset.number).includes(filters.knivesetNumber)) {
            return false;
        }

        const lastTransition = getLastTransition(kniveset);

        return !(filters.knivesetStatusType
            && (!lastTransition || filters.knivesetStatusType !== lastTransition.status_type));
    });
