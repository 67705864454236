enum KnivesetStatusType {
  NEW = 'NEW',
  AT_FARM_NOT_IN_USE = 'AT_FARM_NOT_IN_USE',
  AT_FARM_IN_USE = 'AT_FARM_IN_USE',
  AT_FARM_SHARPENING_REQUIRED = 'AT_FARM_SHARPENING_REQUIRED',
  COLLECTED_WAITING_FOR_SHARPENING = 'COLLECTED_WAITING_FOR_SHARPENING',
  SHARPENED_WAITING_FOR_TRANSPORT_TO_FARM = 'SHARPENED_WAITING_FOR_TRANSPORT_TO_FARM',
  OUT_OF_ROTATION = 'OUT_OF_ROTATION'
}

export default KnivesetStatusType;

export const knivesetStatusTypeLabels = {
    [KnivesetStatusType.NEW]: 'Nieuw',
    [KnivesetStatusType.AT_FARM_NOT_IN_USE]: 'Niet in gebruik (op de boerderij)',
    [KnivesetStatusType.AT_FARM_IN_USE]: 'In gebruik',
    [KnivesetStatusType.AT_FARM_SHARPENING_REQUIRED]: 'Te slijpen',
    [KnivesetStatusType.COLLECTED_WAITING_FOR_SHARPENING]: 'Opgehaald',
    [KnivesetStatusType.SHARPENED_WAITING_FOR_TRANSPORT_TO_FARM]: 'Geslepen',
    [KnivesetStatusType.OUT_OF_ROTATION]: 'Buiten bedrijf',
};

export function getKnivesetStatusTypeLabel(status: KnivesetStatusType): string {
    return knivesetStatusTypeLabels[status];
}
