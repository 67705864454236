
import { Component, Prop, Vue } from 'vue-property-decorator';
import Kniveset from '@/interfaces/Kniveset';
import KnivesetStatusType from '@/enums/KnivesetStatusType';
import KnivesetConfigurationForm from '@/components/forms/KnivesetConfigurationForm.vue';
import KnivesetStatusTransitionForm from '@/components/forms/KnivesetStatusTransitionForm.vue';
import KnivesetsTable from '@/components/KnivesetsTable.vue';

@Component({
    components: {
        KnivesetsTable,
        KnivesetStatusTransitionForm,
        KnivesetConfigurationForm,
    },
})
export default class KnivesetsSelectStatusTransition extends Vue {
    @Prop()
    knivesets!: Kniveset[];

    statusType = KnivesetStatusType;

    showStatusTransitionModal(knivesets: Kniveset[], status: KnivesetStatusType): void {
        this.$emit('transitionKnivesets', {
            knivesets,
            status,
        });
    }
}

