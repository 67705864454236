
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({
    components: {},
})
export default class ModalFooter extends Vue {
    @Prop({ default: false, type: Boolean })
    saving!: boolean;

    @Prop({ default: false, type: Boolean })
    showDelete!: boolean;

    @Prop({ default: false, type: Boolean })
    disableSave!: boolean;

    @Prop({ default: false, type: Boolean })
    showReset!: boolean;

    @Prop({ default: 'Opslaan' })
    saveLabel!: string;
}
