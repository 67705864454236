

import { Component, Prop, Vue } from 'vue-property-decorator';
import Kniveset from '@/interfaces/Kniveset';
import { Action, Getter } from 'vuex-class';
import Farm from '@/interfaces/Farm';
import ModalFooter from '@/components/ModalFooter.vue';

@Component({
    components: { ModalFooter },
})
export default class KnivesetConfigurationForm extends Vue {
    @Prop()
    initialKniveset!: Partial<Kniveset>;

    @Getter('farms')
    farms!: Farm[];

    @Action('saveKniveset')
    saveKniveset!: (kniveset: Partial<Kniveset>) => Promise<void>;

    description = '';

    number: number | null = null;

    saving = false;

    farmId: number | null = null;

    mounted() {
        if (this.initialKniveset) {
            this.farmId = this.initialKniveset.farm_id || null;
            this.description = this.initialKniveset.description || '';
            this.number = this.initialKniveset.number || null;
        }
    }

    get parseKnivesetFromForm(): Partial<Kniveset> {
        return {
            id: this.initialKniveset?.id,
            description: this.description,
            farm_id: this.farmId,
            number: this.number || 0,
        };
    }

    async submit(): Promise<void> {
        this.saving = true;
        (this.$refs.form as HTMLFormElement).reportValidity();

        try {
            await this.saveKniveset(this.parseKnivesetFromForm);
        } catch (err) {
            // noop
        } finally {
            this.saving = false;
        }

        this.$emit('close');
    }

    get farmOptions(): { id: number | null, text: string }[] {
        return [{
            id: null,
            text: 'Niet gekoppeld aan een boerderij',
        }, ...this.farms.map(farm => ({
            id: farm.id,
            text: farm.name,
        }))];
    }
}
