

import { Component, Prop, Vue } from 'vue-property-decorator';
import Kniveset from '@/interfaces/Kniveset';
import ModalFooter from '@/components/ModalFooter.vue';
import {
    endOfMonth,
    format,
    isAfter,
    isWithinInterval,
    parse,
    parseISO,
    startOfMonth,
    startOfToday,
    subMonths,
} from 'date-fns';
import ExportStatusTransitions from '@/core/ExportStatusTransitions';
import { Getter } from 'vuex-class';
import Farm from '@/interfaces/Farm';

@Component({
    components: { ModalFooter },
})
export default class KnivesetStatusTransitionsExportForm extends Vue {
    @Prop({ required: true })
    knivesets!: Kniveset[];

    @Getter('farms')
    farms!: Farm[];

    dateFrom = '';

    dateUntil = '';

    saving = false;

    mounted(): void {
        const startOfLastMonth = startOfMonth(subMonths(new Date(), 1));
        this.dateFrom = format(startOfLastMonth, 'yyyy-MM-dd');
        this.dateUntil = format(endOfMonth(startOfLastMonth), 'yyyy-MM-dd');
    }

    async submit(): Promise<void> {
        (this.$refs.form as HTMLFormElement).reportValidity();

        this.saving = true;

        const interval = {
            start: parse(this.dateFrom, 'yyyy-MM-dd', startOfToday()),
            end: parse(this.dateUntil, 'yyyy-MM-dd', startOfToday()),
        };

        const transitions = this.knivesets
            .flatMap(kniveset => (kniveset.transitions || []).map(transition => ({
                ...transition,
                kniveset,
            })) || [])
            .filter(transition => isWithinInterval(parseISO(transition.created_at), interval))
            .sort((a, b) => a.id - b.id)
            .sort((a, b) => (isAfter(
                parseISO(a.created_at),
                parseISO(b.created_at),
            ) ? 1 : -1));

        const exportStatusTransitions = new ExportStatusTransitions(transitions, this.farms);
        await exportStatusTransitions.write(
            `export-knivesets-${format(interval.start, 'dd-MM-yyyy')}-${format(interval.end, 'dd-MM-yyyy')}`,
        );

        this.$emit('close');
    }
}

