enum SharpeningRequiredReason {
    UNKNOWN = 0,
    VERY_BAD = 1,
    BAD = 2,
    BROKE = 3,
    PREVENTIVE = 4,
}

export default SharpeningRequiredReason;

export const getSharpeningRequiredReasonLabel = (reason: SharpeningRequiredReason): string => {
    switch (reason) {
        case SharpeningRequiredReason.VERY_BAD:
            return 'Erg slecht';
        case SharpeningRequiredReason.BAD:
            return 'Slecht';
        case SharpeningRequiredReason.BROKE:
            return 'Beschadigd';
        case SharpeningRequiredReason.PREVENTIVE:
            return 'Preventief';
        case SharpeningRequiredReason.UNKNOWN:
        default:
            return 'Onbekend';
    }
};
