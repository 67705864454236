
import { Component, Vue } from 'vue-property-decorator';
import store from '@/store';

@Component({})
export default class LoginView extends Vue {
    async login(): Promise<void> {
        await store.dispatch('sync');

        await this.$router.push({
            name: 'knivesets.index',
        });
    }
}

