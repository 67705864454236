import Vue from 'vue';
import VueRouter, { RouteConfig } from 'vue-router';
import { LelyAuth } from '@tec/frontend-vue-shared';
import KnivesetsView from '@/views/KnivesetsView.vue';
import LoginView from '@/views/LoginView.vue';

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
    {
        path: '/login',
        component: LoginView,
        name: 'login',
        meta: {
            guest: true,
        },
    },
    {
        path: '/',
        name: 'knivesets.index',

        component: KnivesetsView,
    },
];

const router = new VueRouter({
    routes,
});

router.beforeEach((to, from, next) => {
    if (to.matched.some(route => route.meta.guest)) {
        if (!LelyAuth.isAuthenticated()) {
            return next();
        }
        return next({ name: 'knivesets.index' });
    }

    if (LelyAuth.isAuthenticated()) {
        return next();
    }

    return next({ name: 'login' });
});

export default router;
