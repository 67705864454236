import { ActionContext } from 'vuex';
import RootState from '@/store/rootState';
import axios from 'axios';
import Kniveset from '@/interfaces/Kniveset';

const saveOrUpdate = async <T extends { id: number }>(baseUrl: string, payload: Partial<T>): Promise<T> => {
    let res;
    if (payload.id) {
        res = (await axios.put<T>(`${baseUrl}/${payload.id}`, payload)).data;
    } else {
        res = (await axios.post<T>(baseUrl, payload)).data;
    }

    return res;
};

export const sync = async(context: ActionContext<RootState, RootState>): Promise<void> => {
    await Promise.all([
        context.dispatch('syncFarms'),
        context.dispatch('syncKnivesets'),
    ]);
};

export const syncFarms = async(context: ActionContext<RootState, RootState>): Promise<void> => {
    const res = await axios.get('/kniveset/farms');
    context.commit('setFarms', res.data);
};

export const syncKnivesets = async(context: ActionContext<RootState, RootState>): Promise<void> => {
    const res = await axios.get('/kniveset/knivesets');
    context.commit('setKnivesets', res.data);
};

export const saveKniveset = async(context: ActionContext<RootState, RootState>, payload: Partial<Kniveset>): Promise<void> => {
    const kniveset = await saveOrUpdate<Kniveset>('/kniveset/knivesets', payload);

    context.commit('saveKniveset', kniveset);
};

export const saveKnivesetTransitions = async(context: ActionContext<RootState, RootState>, payload: Kniveset[]): Promise<void> => {
    await axios.post('/kniveset/knivesets/transitions', payload);
    await context.dispatch('syncKnivesets');
};
