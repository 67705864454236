import RootState from '@/store/rootState';
import Farm from '@/interfaces/Farm';
import Kniveset from '@/interfaces/Kniveset';
import Vue from 'vue';

const updateItemInState = <T extends { id: number }>(arr: T[], payload: T) => {
    const index = arr.findIndex(item => item.id === payload.id);

    if (index > -1) {
        Vue.set(arr, index, payload);
    } else {
        arr.push(payload);
    }
};

export const setFarms = (state: RootState, payload: Farm[]): void => {
    state.farms = payload;
};

export const setKnivesets = (state: RootState, payload: Kniveset[]): void => {
    state.knivesets = payload;
};

export const saveKniveset = (state: RootState, payload: Kniveset): void => {
    updateItemInState(state.knivesets, payload);
};
