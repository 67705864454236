

import { Component, Prop, Vue } from 'vue-property-decorator';
import Kniveset from '@/interfaces/Kniveset';
import ModalFooter from '@/components/ModalFooter.vue';
import KnivesetStatusType from '@/enums/KnivesetStatusType';
import { format } from 'date-fns';
import SharpeningRequiredReason, {
    getSharpeningRequiredReasonLabel,
} from '@/enums/SharpeningRequiredReason';
import { Action } from 'vuex-class';

@Component({
    components: { ModalFooter },
})
export default class KnivesetStatusTransitionForm extends Vue {
    @Prop()
    knivesets!: Kniveset[];

    @Prop()
    knivesetStatusType!: KnivesetStatusType;

    @Action('saveKnivesetTransitions')
    saveKnivesetTransitions!: (payload: Kniveset[]) => Promise<void>;

    saving = false;

    dates: string[] = [];

    notes: string[] = [];

    sharpeningRequiredReasons: (SharpeningRequiredReason | null)[] = [];

    get sharpeningRequiredReasonRequired() {
        return this.knivesetStatusType === KnivesetStatusType.AT_FARM_SHARPENING_REQUIRED;
    }

    get sharpeningRequiredReasonOptions(): { text: string, value: SharpeningRequiredReason }[] {
        return [
            {
                text: getSharpeningRequiredReasonLabel(SharpeningRequiredReason.UNKNOWN),
                value: SharpeningRequiredReason.UNKNOWN,
            },
            {
                text: getSharpeningRequiredReasonLabel(SharpeningRequiredReason.VERY_BAD),
                value: SharpeningRequiredReason.VERY_BAD,
            },
            {
                text: getSharpeningRequiredReasonLabel(SharpeningRequiredReason.BAD),
                value: SharpeningRequiredReason.BAD,
            },
            {
                text: getSharpeningRequiredReasonLabel(SharpeningRequiredReason.BROKE),
                value: SharpeningRequiredReason.BROKE,
            },
            {
                text: getSharpeningRequiredReasonLabel(SharpeningRequiredReason.PREVENTIVE),
                value: SharpeningRequiredReason.PREVENTIVE,
            },
        ];
    }

    mounted(): void {
        this.dates = this.knivesets.map(() => format(new Date(), 'yyyy-MM-dd'));
        this.notes = this.knivesets.map(() => '');
        this.sharpeningRequiredReasons = this.knivesets.map(() => null);
    }

    async submit(): Promise<void> {
        if (!(this.$refs.form as HTMLFormElement).reportValidity()) {
            return;
        }

        this.saving = true;

        const data = this.knivesets.map((kniveset, index) => ({
            ...kniveset,
            transitions: [
                {
                    status_type: this.knivesetStatusType,
                    created_at: this.dates[index],
                    notes: this.notes[index],
                    sharpening_required_reason: this.sharpeningRequiredReasonRequired
                        ? this.sharpeningRequiredReasons[index]
                        : undefined,
                },
            ],
        })) as Kniveset[];

        await this.saveKnivesetTransitions(data);

        this.saving = false;

        this.$emit('close');
    }
}
