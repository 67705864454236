import ExcelJS, { Workbook, Worksheet } from 'exceljs';
import KnivesetStatusType, { getKnivesetStatusTypeLabel } from '@/enums/KnivesetStatusType';
import { parseISO } from 'date-fns';
import SharpeningRequiredReason, {
    getSharpeningRequiredReasonLabel,
} from '@/enums/SharpeningRequiredReason';
import { saveAs } from 'file-saver';
import Farm from '@/interfaces/Farm';
import KnivesetStatusTransition from '@/interfaces/KnivesetStatusTransition';
import Kniveset from '@/interfaces/Kniveset';

interface TransitionInput extends KnivesetStatusTransition {
    kniveset: Kniveset;
}

export default class ExportStatusTransitions {
    private workbook: Workbook;

    private sheetTransitions: Worksheet;

    constructor(
        private readonly transitions: TransitionInput[],
        private readonly farms: Farm[],
    ) {
        this.workbook = new ExcelJS.Workbook();
        this.sheetTransitions = this.workbook.addWorksheet('Handelingen');
    }

    public async write(basename: string): Promise<void> {
        this.writeStatusTransitions();

        const xls64 = await this.workbook.xlsx.writeBuffer();

        saveAs(
            new Blob([xls64], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' }),
            `${basename}.xlsx`,
        );
    }

    private writeStatusTransitions(): void {
        const rows = this.transitions
            .map(transition => {
                let status = getKnivesetStatusTypeLabel(transition.status_type);

                if (transition.status_type === KnivesetStatusType.AT_FARM_SHARPENING_REQUIRED) {
                    status = `${status} - ${getSharpeningRequiredReasonLabel(transition.sharpening_required_reason as SharpeningRequiredReason)}`;
                }

                return {
                    id: transition.id,
                    kniveset: transition.kniveset.number,
                    farm: transition.kniveset.farm_id ? (this.farms.find(farm => farm.id === transition.kniveset.farm_id) as Farm).name : '',
                    status,
                    created_at: parseISO(transition.created_at),
                    notes: transition.notes,
                };
            });

        this.sheetTransitions.columns = [
            {
                header: 'Datum',
                key: 'created_at',
                width: 15,
            },
            {
                header: 'Boerderij',
                key: 'farm',
                width: 20,
            },
            {
                header: 'Messenset',
                key: 'kniveset',
                width: 15,
            },
            {
                header: 'Status',
                key: 'status',
                width: 30,
            },
            {
                header: 'Opmerkingen',
                key: 'notes',
                width: 50,
                style: {
                    alignment: {
                        wrapText: true,
                    },
                },
            },
        ];

        this.sheetTransitions.getRow(1).font = { bold: true };
        this.sheetTransitions.addRows(rows);
        this.sheetTransitions.autoFilter = 'A1:E1';
    }
}
